import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, Drawer, ListItem, ListItemButton, ListItemIcon, Tooltip } from '@mui/material'
import {
  LocalShippingOutlined,
  PeopleAltOutlined,
  SvgIconComponent,
  WrapText,
  AssignmentOutlined,
  Inventory,
  HubOutlined,
} from '@mui/icons-material'
import { Translate } from 'react-redux-i18n'
import { useTheme } from 'styled-components'
import Logo from 'components/ui/Logo'
import { MoveTheme } from 'theme'
import { MenuHeader, List } from './styled'

type Route = {
  to: string
  translation: string
  icon: SvgIconComponent
  disabled?: boolean
  displayAtBottom?: boolean
}

const routes: Route[] = [
  {
    to: '/shipments',
    translation: 'UI.Shipments.Title',
    icon: LocalShippingOutlined,
  },
  {
    to: '/itineraries',
    translation: 'UI.Itineraries.Title',
    icon: WrapText,
  },
  {
    to: '/products',
    translation: 'UI.Products.Title',
    icon: Inventory,
  },
  {
    to: '/requests',
    translation: 'UI.Requests.Title',
    icon: AssignmentOutlined,
  },
  {
    to: '/datasharing',
    translation: 'UI.Connections.Title',
    icon: HubOutlined,
  },
  {
    to: '/users',
    translation: 'UI.Users.Title',
    icon: PeopleAltOutlined,
  },
]

type NavbarProps = {
  isPermanent: boolean
  isCollapsed: boolean
  onClose: () => void
}

const Navbar = ({ isPermanent, isCollapsed, onClose }: NavbarProps) => {
  const path = useLocation().pathname
  const navigate = useNavigate()
  const theme = useTheme() as MoveTheme

  return (
    <Drawer
      sx={{
        '& .MuiDrawer-paper': {
          border: 'none',
          width: `${theme.drawer.width}px`,
          background: `${theme.drawer.background}`,
        },
      }}
      variant={isPermanent ? 'permanent' : 'temporary'}
      open={!isCollapsed || isPermanent}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <MenuHeader>
        <Logo square />
      </MenuHeader>
      <Box>
        <List>
          {routes
            .filter((route) => !route.disabled)
            .map((route) => (
              <ListItem key={route.to} disablePadding>
                <Tooltip title={<Translate value={route.translation} />} placement="right">
                  <ListItemButton
                    onClick={() => navigate(route.to)}
                    disabled={route.disabled}
                    selected={path === route.to}
                    sx={{ minHeight: theme.drawer.width }}
                  >
                    <ListItemIcon sx={{ color: 'common.white', minWidth: 0 }}>
                      <route.icon fontSize="medium" />
                    </ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
        </List>
      </Box>
    </Drawer>
  )
}

export default Navbar
