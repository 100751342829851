import React, { useEffect, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { Grid } from '@mui/material'

import { ProductTemplate } from '../index'
import { ValidationError, validateName, validateCarrierProductCode } from './validators'
import { InputField } from './styled'

const validators: { [field: string]: ValidationError } = {
  name: {
    valid: false,
    error: undefined,
  },
  carrierProductCode: {
    valid: false,
    error: undefined,
  },
}

export type CreateProductFormProps = {
  onChange: (user: ProductTemplate) => void
  onValidation: (valid: boolean) => void
}

export const CreateProductForm = ({ onChange, onValidation }: CreateProductFormProps) => {
  const [name, setName] = useState<string>()
  const [carrierProductCode, setCarrierProductCode] = useState<string>()

  const [nameValidation, setNameValidation] = useState(validators.name)
  const [carrierProductCodeValidation, setCarrierProductCodeValidation] = useState(
    validators.carrierProductCode
  )
  const [allValid, setAllValid] = useState(false)

  useEffect(() => {
    setNameValidation(validateName(name))
    setCarrierProductCodeValidation(validateCarrierProductCode(carrierProductCode))
  }, [name, carrierProductCode])

  useEffect(() => {
    const allValidated = nameValidation.valid && carrierProductCodeValidation.valid

    setAllValid(allValidated)
  }, [nameValidation, carrierProductCodeValidation])

  useEffect(() => {
    onValidation(allValid)
  }, [allValid, onValidation])

  useEffect(() => {
    const allSet = !!name && !!carrierProductCode

    if (allSet && allValid) {
      const product = { name, carrierProductCode } as ProductTemplate
      onChange(product)
    }
  }, [name, carrierProductCode, onChange, allValid])

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} xs={12}>
        <InputField
          required
          label={I18n.t('UI.Products.Add.Name')}
          value={name}
          error={!!nameValidation.error}
          helperText={nameValidation.error ?? ''}
          onChange={(e: any) => setName(e.target.value)}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <InputField
          required
          label={I18n.t('UI.Products.Add.PC')}
          value={carrierProductCode}
          error={!!carrierProductCodeValidation.error}
          helperText={carrierProductCodeValidation.error ?? ''}
          onChange={(e: any) => setCarrierProductCode(e.target.value)}
        />
      </Grid>
    </Grid>
  )
}

export default CreateProductForm
