import { I18n } from 'react-redux-i18n'

export type ValidationError = {
  valid: boolean
  error: string | undefined
}

export const validateName = (name: string | undefined): ValidationError => {
  // No interaction from user if value is null
  if (name === undefined) return { valid: false, error: undefined }

  let error

  if (!name) {
    error = I18n.t('UI.Products.Add.Validation.Name')
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}

export const validateCarrierProductCode = (name: string | undefined): ValidationError => {
  // No interaction from user if value is null
  if (name === undefined) return { valid: false, error: undefined }

  let error

  if (!name) {
    error = I18n.t('UI.Products.Add.Validation.PC')
  } else {
    return { valid: true, error: undefined }
  }

  return {
    valid: false,
    error,
  }
}
