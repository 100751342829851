import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit'
import APIStatus from 'types/Status'
import CarrierProduct from 'types/CarrierProduct'
import { StoreNames } from 'types/Misc'
import { getCarrierProducts, clearCarrierProducts, removeProduct } from './actions'

type InitialState = EntityState<CarrierProduct> & {
  status: APIStatus
  error: string | undefined
}

export const entityAdapter = createEntityAdapter<CarrierProduct>({
  selectId: (carrierProduct) => carrierProduct.id,
})

const carrierProductsSlice = createSlice({
  name: StoreNames.carrierProducts,
  initialState: entityAdapter.getInitialState({
    status: APIStatus.Idle,
    error: undefined,
  }) as InitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCarrierProducts.pending, (state) => {
      state.status = APIStatus.Loading
    })
    builder.addCase(getCarrierProducts.success, (state, { payload }) => {
      state.status = APIStatus.Succeeded
      entityAdapter.setAll(state, payload)
    })
    builder.addCase(getCarrierProducts.failure, (state, { payload }) => {
      state.status = APIStatus.Failed
      state.error = payload
    })
    builder.addCase(clearCarrierProducts, (state) => {
      state.status = APIStatus.Idle
      state.error = undefined
      entityAdapter.setAll(state, [])
    })
    builder.addCase(removeProduct, (state, { payload }) => {
      entityAdapter.removeOne(state, payload.productId)
    })
  },
})

export default carrierProductsSlice
