import React, { ReactNode } from 'react'
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import { I18n, Translate } from 'react-redux-i18n'

import { CustomColumnOptions } from 'helpers/types'
import columnConstructors from 'components/features/data/columnConstructors'

import mergeColumnOptions from 'helpers/mergeColumnOptions'

export enum CarrierProductColumns {
  Id = 'id',
  Name = 'name',
  CarrierProductCode = 'carrierProductCode',
  Actions = 'actions',
}

const getCarrierProductColumns: (
  actions: (tableMeta: MUIDataTableMeta) => ReactNode,
  columnOptions?: CustomColumnOptions
) => MUIDataTableColumn[] = (actions, columnOptions) => {
  const columns: MUIDataTableColumn[] = [
    {
      label: I18n.t('UI.Products.Table.Id'),
      name: CarrierProductColumns.Id,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Products.Table.Id" />,
      },
    },
    {
      label: I18n.t('UI.Products.Table.Name'),
      name: CarrierProductColumns.Name,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Products.Table.Name" />,
        customBodyRender: (name) => name ?? '-',
        filter: true,
      },
    },
    {
      label: I18n.t('UI.Products.Table.PC'),
      name: CarrierProductColumns.CarrierProductCode,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Products.Table.PC" />,
        filter: true,
      },
    },
    columnConstructors.actions(actions, CarrierProductColumns.Actions),
  ]

  mergeColumnOptions(columnOptions, columns)

  return columns
}

export default getCarrierProductColumns
