import React, { useEffect, useState } from 'react'
import {
  Avatar,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import { Error, LocalShipping, ArrowRightAlt } from '@mui/icons-material'
import { Translate } from 'react-redux-i18n'

import api from 'api'
import APIStatus from 'types/Status'

import { CheckCircle } from './styled'
import { CarrierProduct, TransportRequest } from 'types'
import AcceptRequestForm, { AcceptRequestTemplate } from './AcceptRequestForm'

type TrackableTransportRequest = {
  request: TransportRequest
  status: APIStatus
}

const allCompleted = (trackable: TrackableTransportRequest[]) =>
  trackable.length > 0 && trackable.every((t) => t.status === APIStatus.Succeeded)

const isAnyLoading = (trackable: TrackableTransportRequest[]) =>
  trackable.some((t) => t.status === APIStatus.Loading)

const isNotCompleted = (trackable: TrackableTransportRequest) =>
  trackable.status !== APIStatus.Succeeded

export type AcceptRequestDialogProps = {
  onClose: (() => void) | ((wasActionPerformed: boolean) => void)
  transportRequests: TransportRequest[]
  carrierProducts: CarrierProduct[]
}

const AcceptRequestDialog = ({
  transportRequests,
  carrierProducts,
  onClose,
}: AcceptRequestDialogProps) => {
  const [trackable, setTrackable] = useState<TrackableTransportRequest[]>([])
  const [isRetrying, setIsRetrying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [isClosing, setIsClosing] = useState(false)
  const [userDidInteract, setUserDidInteract] = useState(false)
  const [formStates, setFormStates] = useState<Record<string, AcceptRequestTemplate>>({})
  const [errorMessages, setErrorMessages] = useState<Record<string, string>>({})

  const onFormChange = (id: string, data: AcceptRequestTemplate) =>
    setFormStates((old) => ({ ...old, [id]: data }))

  const multiple = trackable.length > 1

  useEffect(() => {
    const items: TrackableTransportRequest[] = transportRequests.map((request) => ({
      request,
      status: APIStatus.Idle,
    }))

    setTrackable(items)
  }, [transportRequests])

  useEffect(() => {
    setIsLoading(isAnyLoading(trackable))
    setIsDone(allCompleted(trackable))
  }, [trackable])

  useEffect(() => {
    if (isDone) {
      setIsClosing(true)
      setTimeout(() => {
        onClose(true)
      }, 1500)
    }
  }, [isDone, onClose])

  const handleClose = () => {
    if (userDidInteract) {
      onClose(true)
    } else {
      onClose(false)
    }
  }

  const acceptTransportRequests = () => {
    if (trackable.filter((t) => !!formStates[t.request.id]).length !== trackable.length) return

    setUserDidInteract(true)
    setTrackable((prevState) =>
      prevState.map((t) => {
        if (!isNotCompleted(t)) return t

        return { ...t, status: APIStatus.Loading }
      })
    )

    trackable.forEach((t, index) => {
      if (!isNotCompleted(t)) return

      const formState = formStates[t.request.id]

      if (!formState) return

      api.TransportRequest.accept({
        requestId: t.request.id,
        carrierProductCode: formState.productCode,
        estimatedDeliveryDate: formState.deliveryDate,
        estimatedPickupDate: formState.pickupDate,
        freightCharge: formState.freightCharge,
      })
        .then(() => {
          setTrackable((prevState) => {
            return Object.assign([], prevState, {
              [index]: {
                request: prevState[index].request,
                status: APIStatus.Succeeded,
              },
            })
          })
        })
        //Handle errors returned from GraphQL Query. It has to parse the error message to get the validation JSON
        .catch((err) => {
          let validationErrors = 'An unexpected error occurred'
          //get validation JSON from error message with regex
          const match = err.message.match(/text: (.*)/)
          if (match && match[1]) {
            try {
              const parsedError = JSON.parse(match[1])
              validationErrors = parsedError.validationErrors
                .map((error: any) => error.errorMessage)
                .join(', ')
            } catch (parseError) {
              // eslint-disable-next-line no-console
              console.error(parseError)
            }
          } else {
            //some error messages are just returned without any type of formatting. This handles those errors.
            if (err.message) {
              validationErrors = err.message
            }
          }
          setErrorMessages((prevMessages) => ({
            ...prevMessages,
            [t.request.id]: validationErrors,
          }))
          setIsRetrying(true)
          setTrackable((prevState) => {
            return Object.assign([], prevState, {
              [index]: {
                request: prevState[index].request,
                status: APIStatus.Failed,
              },
            })
          })
        })
    })
  }

  const statusIcon = (status: APIStatus) => {
    switch (status) {
      case APIStatus.Loading:
        return <CircularProgress />
      case APIStatus.Succeeded:
        return <CheckCircle color="action" />
      case APIStatus.Failed:
        return <Error color="secondary" />
      default:
        return null
    }
  }

  const getContentText = () => {
    const basePath = `UI.Requests.StatusMessages.Accept${multiple ? 'Multiple' : ''}`
    const remainingAmount = trackable.filter(isNotCompleted).length
    const id = trackable[0]?.request?.id

    if (isDone) {
      return <Translate value={`${basePath}.Success`} amount={trackable.length} id={id} />
    }

    if (isRetrying) {
      return (
        <>
          <Translate value={`${basePath}.Failed`} amount={remainingAmount} id={id} />
          {'. '}
          <Translate value="General.Questions.Retry" />
        </>
      )
    }

    return <Translate value={`${basePath}.Prompt`} amount={remainingAmount} id={id} />
  }

  return (
    <Dialog
      open
      onClose={() => handleClose()}
      aria-labelledby="assign-user-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="assign-user-dialog-title">
        <Translate value={`UI.Requests.Table.AcceptRequest${multiple ? 's' : ''}`} />
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{getContentText()}</DialogContentText>
        <List>
          {trackable.map((t) => (
            <React.Fragment key={t.request.id}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <LocalShipping />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={t.request.id}
                  secondary={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      {t.request.sourceAddress.address1}
                      <br />
                      {t.request.sourceAddress.city}
                      <br />
                      {t.request.sourceAddress.zipCode}
                      &nbsp;
                      <span style={{ padding: '0 16px' }}>
                        <ArrowRightAlt />
                      </span>
                      &nbsp;
                      {t.request.destinationAddress.address1}
                      <br />
                      {t.request.destinationAddress.city}
                      <br />
                      {t.request.destinationAddress.zipCode}
                      <br />
                    </span>
                  }
                />
                {statusIcon(t.status)}
              </ListItem>
              <ListItem>
                <AcceptRequestForm
                  products={carrierProducts}
                  transportRequest={t.request}
                  onChange={(data) => onFormChange(t.request.id, data)}
                />
              </ListItem>
              {errorMessages[t.request.id] && (
                <div style={{ color: 'red' }}>{errorMessages[t.request.id]}</div>
              )}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isClosing || isLoading}
          onClick={() => acceptTransportRequests()}
          color="primary"
        >
          {isRetrying ? <Translate value="General.Retry" /> : <Translate value="General.Yes" />}
        </Button>
        <Button disabled={isClosing || isLoading} onClick={() => onClose(false)} color="error">
          <Translate value="General.No" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AcceptRequestDialog
