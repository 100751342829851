import React, { useState } from 'react'
import { Translate } from 'react-redux-i18n'

import CreateProductModal from 'components/features/products/CreateProductModal'
import InventoryIcon from '@mui/icons-material/Inventory'
import Title from 'components/ui/Title'
import ProductList from 'components/features/products/ProductList'
import { Box, Button } from '@mui/material'

const Products = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const onInvite = (success: boolean) => {
    if (success) {
      setModalOpen(false)
    }
  }
  return (
    <>
      <Title>
        <Translate value="UI.Products.Title" />
      </Title>
      <CreateProductModal
        onClose={() => setModalOpen(false)}
        onInvite={onInvite}
        isVisible={modalOpen}
      />
      <Box mb={4} mt={-4}>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setModalOpen(true)}
          startIcon={<InventoryIcon />}
        >
          <Translate value="UI.Products.Add.AddProduct" />
        </Button>
      </Box>
      <ProductList />
    </>
  )
}

export default Products
