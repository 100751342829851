import { apolloClient } from 'api/client'
import { parseResult } from 'api/util'
import { GetCarrierProductsQuery } from './queries'
import { RemoveProductMutation, CreateProductMutation } from './mutations'
import * as GQL from 'gql/graphql'

const api = {
  getAll: async () => {
    const res = await apolloClient.query({
      query: GetCarrierProductsQuery,
    })
    return (parseResult(res, 'products') ?? []) as GQL.Product[]
  },
  remove: async (data: GQL.RemoveProductInput) => {
    try {
      const res = await apolloClient.mutate({
        mutation: RemoveProductMutation,
        variables: { data },
      })
      const parsedResult = parseResult(res, 'removeProduct')
      return parsedResult as GQL.Product
    } catch (error) {
      throw error
    }
  },
  createProduct: async (data: GQL.CreateProductInput) => {
    const res = await apolloClient.mutate({
      mutation: CreateProductMutation,
      variables: { data },
    })

    const payload = parseResult(res, 'createProduct') as GQL.CreateProductInput
    return payload
  },
}

export default api
