import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import { useLogtradeAuth } from '@logtrade-technology-ab/logtrade-lib'

import * as Pages from './pages'
import api from 'api'
import { getGlobalData } from 'store/root'
import BackgroundWorker from './features/misc/BackgroundWorker'
import AuthGate from './features/auth/AuthGate'
import Layout from './layout'

import { useTheme } from 'helpers/hooks'

const useAuth = () => {
  const dispatch = useDispatch()

  const { isAuthenticated, token } = useLogtradeAuth()

  useEffect(() => {
    if (isAuthenticated) {
      api.setToken(token as string)
      dispatch(getGlobalData())
    }
  }, [dispatch, isAuthenticated, token])
}

export function App() {
  useAuth()
  const theme = useTheme()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BackgroundWorker />
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="login" element={<Pages.LoginPage />} />
            <Route element={<AuthGate />}>
              <Route element={<Layout />}>
                <Route path="shipments">
                  <Route index element={<Pages.Shipments />} />
                  <Route path=":id" element={<Pages.ShipmentDetails />} />
                </Route>
                <Route path="users">
                  <Route index element={<Pages.Users />} />
                  <Route path=":id" element={<Pages.UserDetails />} />
                </Route>
                <Route path="products">
                  <Route index element={<Pages.Products />} />
                  <Route path=":id" element={<Pages.ProductDetails />} />
                </Route>
                <Route path="itineraries">
                  <Route index element={<Pages.Itineraries />} />
                  <Route path="create" element={<Pages.ItineraryCreate />} />
                  <Route path=":id" element={<Pages.ItineraryDetails />} />
                </Route>
                <Route path="requests">
                  <Route index element={<Pages.TransportRequests />} />
                </Route>
                <Route path="datasharing">
                  <Route index element={<Pages.Connections />} />
                </Route>
                <Route path="settings" element={<Pages.Settings />} />
                <Route path="*" element={<Navigate to="shipments" replace />} />
              </Route>
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

export default App
