import { graphql } from 'gql/gql'
export const ProductFragment = graphql(`
  fragment ProductFragment on Product {
    id
    name
    carrierProductCode
  }
`)
export const GetCarrierProductsQuery = graphql(`
  query GetCarrierProducts {
    products {
      id
      name
      carrierProductCode
    }
  }
`)
