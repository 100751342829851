import { createAction } from '@reduxjs/toolkit'
import { GetDataArgs } from 'store/root'
import { createAsyncAction, createActionWithCallback } from 'store/utils/actionCreators'
import { CarrierProduct, StoreNames, ProductRemoveParams, ProductCreateParams } from 'types'

const name = StoreNames.carrierProducts

export const setCarrierProduct = createAction<CarrierProduct>(`${name}_SET_ONE`)
export const createProduct = createActionWithCallback<ProductCreateParams>(`${name}_CREATE`)
export const setCarrierProducts = createAction<CarrierProduct[]>(`${name}_SET_MANY`)
export const removeProduct = createAction<ProductRemoveParams>(`${name}_REMOVE_ONE`)
export const removeProducts = createAction<string[]>(`${name}_REMOVE_MANY`)
export const getCarrierProducts = createAsyncAction<
  GetDataArgs,
  undefined,
  CarrierProduct[],
  string
>(name)
export const clearCarrierProducts = createAction(`${name}_CLEAR`)
