import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { useParams } from 'react-router-dom'

import { RootState } from 'store'
import { selectDisplayableShipments } from 'store/shipments/selectors'
import { selectUserById } from 'store/users/selectors'
import ShipmentsList from 'components/features/shipments/ShipmentList'
import { ShipmentColumns } from 'components/features/shipments/ShipmentList/columns'
import ItineraryList from 'components/features/itineraries/ItineraryList'
import { ItineraryColumns } from 'components/features/itineraries/ItineraryList/columns'
import EntitlementChip from 'components/features/user/UserList/styled'
import FloatingField from 'components/ui/DetailPages/FloatingField'
import LoadingBox from 'components/ui/LoadingBox'
import Title from 'components/ui/Title'

import { selectAllItineraries } from 'store/itineraries/selectors'

export const USER_SHIPMENT_TABLE_CONFIG_NAME = 'USER_SHIPMENTS'
export const USER_ITINERARY_TABLE_CONFIG_NAME = 'USER_ITINERARIES'

const CarrierProductDetails = () => {
  const { id } = useParams()

  const user = useSelector((state: RootState) => selectUserById(state, id ?? ''))

  const assignedShipments = useSelector(selectDisplayableShipments).filter(
    (shipment) => shipment.assignedUserId === id
  )

  const assignedItineraries = useSelector(selectAllItineraries).filter(
    (i) => i.assignedUser?.id === id
  )

  if (!user) {
    return (
      <LoadingBox>
        <Translate value="UI.Users.Details.Loading" />
      </LoadingBox>
    )
  }

  return (
    <>
      <Title id={id}>
        <Translate value="Entities.User.Singular" />{' '}
        <span style={{ fontWeight: 'bold' }}>{user.fullName}</span>
      </Title>

      <Grid container spacing={2}>
        <FloatingField label={<Translate value="UI.Users.Details.Email" />}>
          {user.email}
        </FloatingField>
        <FloatingField label={<Translate value="UI.Users.Details.Entitlements" />}>
          <EntitlementChip
            key={user.role}
            label={<Translate value={`Entities.User.Roles.${user.role}`} />}
          />
        </FloatingField>
      </Grid>
      <Typography variant="h5" sx={{ mb: 4 }}>
        <Translate value="UI.Users.Details.AssignedShipments" />
      </Typography>
      <Box mb={4}>
        <ShipmentsList
          name={USER_SHIPMENT_TABLE_CONFIG_NAME}
          data={assignedShipments}
          columnOptions={{
            [ShipmentColumns.FullName]: {
              filter: false,
            },
          }}
        />
      </Box>
      <Typography variant="h5" sx={{ mb: 4 }}>
        <Translate value="UI.Users.Details.AssignedItineraries" />
      </Typography>
      <ItineraryList
        name={USER_ITINERARY_TABLE_CONFIG_NAME}
        data={assignedItineraries}
        columnOptions={{
          [ItineraryColumns.Courier]: {
            filter: false,
          },
        }}
      />
    </>
  )
}

export default CarrierProductDetails
