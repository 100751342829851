import { Dialog, List } from '@mui/material'
import styled from 'styled-components'

export const CreateProductDialog = styled(Dialog)`
  & .MuiDialog-paper {
    max-width: 90%;
    width: 1100px;
    padding: ${(p) => p.theme.spacing(2)};

    ${(p) => p.theme.breakpoints.down('sm')} {
      padding: ${(p) => p.theme.spacing(1)};
    }
  }
`

export const CreateProductList = styled(List)`
  & > li {
    padding-bottom: ${(p) => p.theme.spacing(1.5)};
    margin-bottom: ${(p) => p.theme.spacing(1)};
  }
  & > li:nth-last-child(1) {
    padding-bottom: 0;
  }
`

export default CreateProductDialog
