import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import { Clear, Replay as ReloadIcon, MoreHoriz } from '@mui/icons-material'

import { getCarrierProducts } from 'store/carrierProducts/actions'
import { selectAllCarrierProducts } from 'store/carrierProducts/selectors'
import { newSnackbar } from 'store/snackbars/actions'
import { getGlobalData } from 'store/root'
import { RootState } from 'store'
import { APIStatus, CarrierProduct } from 'types'
import DataGrid, { SORT_ORDER } from 'components/features/data/DataGrid'
import ActionButton from 'components/features/data/DataGrid/ActionButton'
import { CustomColumnOptions } from 'helpers/types'

import getCarrierProductColumns, { CarrierProductColumns } from './columns'
import RemoveDialog from './RemoveDialog'
import { IconButton } from '@mui/material'

const TABLE_CONFIG_NAME = 'PRODUCTS'

type CarrierProductListProps = {
  actions?: ReactNode
  columnOptions?: CustomColumnOptions
}

const ProductList = ({ actions, columnOptions }: CarrierProductListProps) => {
  const dispatch = useDispatch()

  const data: CarrierProduct[] = useSelector(selectAllCarrierProducts)
  const apiStatus = useSelector((state: RootState) => state.carrierProducts.status)

  useEffect(() => {
    if (apiStatus === APIStatus.Idle) {
      dispatch(getCarrierProducts())
    } else if (apiStatus === APIStatus.Failed) {
      dispatch(
        newSnackbar({
          message: <Translate value="UI.Products.Errors.LoadFailed" />,
          status: APIStatus.Failed,
        })
      )
    }
  }, [apiStatus, dispatch])

  const [productToRemove, setProductToRemove] = useState<CarrierProduct['id'] | undefined>()
  const rowActions = useCallback((tableMeta: MUIDataTableMeta) => {
    return (
      <ActionButton
        icon={<Clear />}
        color="error"
        label={<Translate value="UI.Products.Table.Remove" />}
        onClick={() => setProductToRemove(tableMeta.rowData[0])}
      />
    )
  }, [])

  const columns: MUIDataTableColumn[] = useMemo(
    () => getCarrierProductColumns(rowActions, columnOptions),
    [rowActions, columnOptions]
  )

  const defaults = {
    sort: {
      name: CarrierProductColumns.Name,
      direction: SORT_ORDER.ASCENDING,
    },
  }

  const toolbar = () => (
    <>
      <ActionButton
        icon={<ReloadIcon />}
        label={<Translate value="UI.Table.Reload" />}
        onClick={() => dispatch(getGlobalData())}
      />
      {actions}
    </>
  )

  return (
    <>
      <RemoveDialog
        isOpen={productToRemove !== undefined}
        carrierProductId={productToRemove ?? ''}
        onClose={() => setProductToRemove(undefined)}
      />
      <DataGrid
        name={TABLE_CONFIG_NAME}
        data={data}
        columns={columns}
        toolbar={toolbar}
        loading={apiStatus === APIStatus.Loading}
        defaults={defaults}
        options={{ selectableRows: 'multiple' }}
        actions={() => (
          <IconButton disabled>
            <MoreHoriz sx={{ marginY: 0.5 }} />
          </IconButton>
        )}
      />
    </>
  )
}

export default ProductList
