/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation RemoveProduct($data: RemoveProductInput!) {\n    removeProduct(data: $data) {\n      ...ProductFragment\n    }\n  }\n": types.RemoveProductDocument,
    "\n  mutation CreateProduct($data: CreateProductInput!) {\n    createProduct(data: $data) {\n      ...ProductFragment\n    }\n  }\n": types.CreateProductDocument,
    "\n  fragment ProductFragment on Product {\n    id\n    name\n    carrierProductCode\n  }\n": types.ProductFragmentFragmentDoc,
    "\n  query GetCarrierProducts {\n    products {\n      id\n      name\n      carrierProductCode\n    }\n  }\n": types.GetCarrierProductsDocument,
    "\n  mutation CreateConnection($data: CreateConnectionInput!) {\n    createConnection(data: $data) {\n      connection {\n        id\n        connectionName\n        createdAt\n        status\n      }\n    }\n  }\n": types.CreateConnectionDocument,
    "\n  query GetConnections {\n    connections {\n      id\n      connectionName\n      createdAt\n      status\n      connectionToSelf\n    }\n  }\n": types.GetConnectionsDocument,
    "\n  mutation CreateItinerary($data: CreateItineraryInput!) {\n    createItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n": types.CreateItineraryDocument,
    "\n  mutation AddShipmentsToItinerary($data: ModifyItineraryInput!) {\n    addShipmentsToItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n": types.AddShipmentsToItineraryDocument,
    "\n  mutation RemoveShipmentsFromItinerary($data: ModifyItineraryInput!) {\n    removeShipmentsFromItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n": types.RemoveShipmentsFromItineraryDocument,
    "\n  mutation ReorderItineraryStop($data: ReorderStopInput!) {\n    reorderStop(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n": types.ReorderItineraryStopDocument,
    "\n  mutation SetItineraryStopOrder($data: SetStopOrderInput!) {\n    setStopOrder(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n": types.SetItineraryStopOrderDocument,
    "\n  mutation CancelItinerary($itineraryId: String!) {\n    cancelItinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n": types.CancelItineraryDocument,
    "\n  mutation AssignItinerary($data: AssignItineraryInput!) {\n    assignItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n": types.AssignItineraryDocument,
    "\n  mutation UnassignItinerary($itineraryId: String!) {\n    unAssignItinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n": types.UnassignItineraryDocument,
    "\n  fragment ItineraryCoreFragment on Itinerary {\n    id\n    name\n    createdAt\n    startDate\n    status\n    shipmentIds\n  }\n": types.ItineraryCoreFragmentFragmentDoc,
    "\n  fragment ItineraryStopFragment on ItineraryStop {\n    id\n    addressString\n    city\n    order\n    geoLocation {\n      lat\n      lng\n    }\n  }\n": types.ItineraryStopFragmentFragmentDoc,
    "\n  fragment ItineraryStopActionFragment on ItineraryStopAction {\n    type\n    status\n    shipment {\n      ...ShipmentFragment\n    }\n  }\n": types.ItineraryStopActionFragmentFragmentDoc,
    "\n  fragment ItineraryFragment on Itinerary {\n    ...ItineraryCoreFragment\n    assignedUser {\n      ...UserFragment\n    }\n    stops {\n      ...ItineraryStopFragment\n      actions {\n        ...ItineraryStopActionFragment\n      }\n    }\n  }\n": types.ItineraryFragmentFragmentDoc,
    "\n  query GetItinerary($itineraryId: String!) {\n    itinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n": types.GetItineraryDocument,
    "\n  query GetItineraries($filter: ItineraryFilterInput!) {\n    itineraries(filter: $filter) {\n      ...ItineraryFragment\n    }\n  }\n": types.GetItinerariesDocument,
    "\n  mutation InviteUser($data: InviteUserInput!) {\n    inviteUser(data: $data) {\n      ...UserFragment\n    }\n  }\n": types.InviteUserDocument,
    "\n  mutation RemoveUser($data: RemoveUserInput!) {\n    removeUser(data: $data) {\n      ...UserFragment\n    }\n  }\n": types.RemoveUserDocument,
    "\n  mutation ConnectOrganizationToIol($data: ConnectOrganizationToIolInput!) {\n    connectOrganizationToIol(data: $data) {\n      connectionUrl\n    }\n  }\n": types.ConnectOrganizationToIolDocument,
    "\n  fragment OrganizationFragment on Organization {\n    id\n    name\n    users {\n      firstName\n      lastName\n      fullName @client\n      role\n      id\n      email\n    }\n  }\n": types.OrganizationFragmentFragmentDoc,
    "\n  query GetOrganization($orgId: String) {\n    organization(orgId: $orgId) {\n      ...OrganizationFragment\n    }\n  }\n": types.GetOrganizationDocument,
    "\n  mutation PickupShipment($data: PickupShipmentInput!) {\n    pickupShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n": types.PickupShipmentDocument,
    "\n  mutation AssignShipment($data: AssignShipmentInput!) {\n    assignShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n": types.AssignShipmentDocument,
    "\n  mutation UnassignShipment($data: UnassignShipmentInput!) {\n    unassignShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n": types.UnassignShipmentDocument,
    "\n  mutation CancelShipment($data: CancelShipmentInput!) {\n    cancelShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n": types.CancelShipmentDocument,
    "\n  fragment ShipmentCoreFragment on Shipment {\n    id\n    iolIdentity\n    assignedUserId\n    carrierCode\n    carrierName\n    carrierProductCode\n    consignmentId\n    status\n    shipmentNumber\n    shipmentDate\n    itineraryIds\n  }\n": types.ShipmentCoreFragmentFragmentDoc,
    "\n  fragment ExtraShipmentInformationFragment on Shipment {\n    bookingInstruction\n    productName\n    productCode\n    paymentIndicator\n    freeTextItems\n    deliveryInstruction\n    termsOfDeliveryText\n    termsOfDeliveryLocation\n    termsOfDeliveryCode\n  }\n": types.ExtraShipmentInformationFragmentFragmentDoc,
    "\n  fragment DangerousGoodsFragment on DangerousGoodsItem {\n    netWeight\n    unNumber\n    typeOfPackageName\n    tunnelRestrictions\n    properties {\n      dataType\n      name\n      value\n    }\n    packingGroup\n    numberOfPackages\n    netVolume\n    marinePollutant\n    identificationNameAlt\n    identificationName\n    id\n    grossWeight\n    flamingPoint\n    classificationCode\n  }\n": types.DangerousGoodsFragmentFragmentDoc,
    "\n  fragment ShipmentAddressFragment on ShipmentAddress {\n    id\n    zipCode\n    smsNumber\n    province\n    properties {\n      dataType\n      name\n      value\n    }\n    phoneNumber\n    name\n    iolIdentity\n    identifiers {\n      name\n      value\n    }\n    geoLocation {\n      lat\n      lng\n    }\n    gateCode\n    faxNumber\n    email\n    countryName\n    countryCode\n    contactPerson\n    city\n    addressType\n    address3\n    address2\n    address1\n  }\n": types.ShipmentAddressFragmentFragmentDoc,
    "\n  fragment PackageItemsFragment on PackageItem {\n    width\n    volume\n    typeOfPackages\n    typeOfPackageCode\n    shippingMark\n    packageNumber\n    loadingmeters\n    length\n    iolIdentity\n    id\n    height\n    grossWeight\n    goodsDescription\n    circumference\n  }\n": types.PackageItemsFragmentFragmentDoc,
    "\n  fragment ShipmentEventsFragment on ShipmentEvent {\n    createdAt\n    eventType\n    geoPoint {\n      lat\n      lng\n    }\n    reasonCode\n    reasonText\n    createdBy\n    assignedUserId\n  }\n": types.ShipmentEventsFragmentFragmentDoc,
    "\n  fragment ShipmentServicesFragment on ShipmentService {\n    serviceName\n    serviceCode\n    properties {\n      dataType\n      name\n      value\n    }\n    description\n    carrierServiceCode\n  }\n": types.ShipmentServicesFragmentFragmentDoc,
    "\n  fragment ShipmentPropertiesFragment on NamedProperty {\n    dataType\n    name\n    value\n  }\n": types.ShipmentPropertiesFragmentFragmentDoc,
    "\n  fragment ShipmentFragment on Shipment {\n    ...ShipmentCoreFragment\n    ...ExtraShipmentInformationFragment\n    sourceAddress {\n      ...ShipmentAddressFragment\n    }\n    destinationAddress {\n      ...ShipmentAddressFragment\n    }\n    shipmentAddresses {\n      ...ShipmentAddressFragment\n    }\n    shipmentServices {\n      ...ShipmentServicesFragment\n    }\n    dangerousGoodsItems {\n      ...DangerousGoodsFragment\n    }\n    properties {\n      ...ShipmentPropertiesFragment\n    }\n    packageItems {\n      ...PackageItemsFragment\n    }\n    shipmentIdentifiers {\n      value\n      name\n    }\n    events {\n      ...ShipmentEventsFragment\n    }\n  }\n": types.ShipmentFragmentFragmentDoc,
    "\n  query GetShipment($shipmentId: String!) {\n    shipment(id: $shipmentId) {\n      ...ShipmentFragment\n    }\n  }\n": types.GetShipmentDocument,
    "\n  query GetShipments($filter: ShipmentFilterInput) {\n    shipments(filter: $filter) {\n      ...ShipmentFragment\n    }\n  }\n": types.GetShipmentsDocument,
    "\n  mutation AcceptTransportRequest($data: AcceptShipmentRequestInput!) {\n    acceptShipmentRequest(data: $data) {\n      ...TransportRequestFragment\n    }\n  }\n": types.AcceptTransportRequestDocument,
    "\n  mutation RejectTransportRequest($data: RejectShipmentRequestInput!) {\n    rejectShipmentRequest(data: $data) {\n      ...TransportRequestFragment\n    }\n  }\n": types.RejectTransportRequestDocument,
    "\n  fragment TransportRequestFragment on ShipmentRequest {\n    id\n    earliestPickupDate\n    latestPickupDate\n    earliestDeliveryDate\n    latestDeliveryDate\n    createdAt\n    status\n    sourceAddress {\n      ...ShipmentAddressFragment\n    }\n    destinationAddress {\n      ...ShipmentAddressFragment\n    }\n    packageItems {\n      ...PackageItemsFragment\n    }\n    maxFreightCharge\n    currencyCode\n  }\n": types.TransportRequestFragmentFragmentDoc,
    "\n  query GetTransportRequests {\n    request {\n      ...TransportRequestFragment\n    }\n  }\n": types.GetTransportRequestsDocument,
    "\n  mutation UpdateUserPassword($data: UpdateUserPasswordInput!) {\n    updateUserPassword(data: $data) {\n      ...UserFragment\n    }\n  }\n": types.UpdateUserPasswordDocument,
    "\n  fragment UserFragment on User {\n    firstName\n    lastName\n    fullName @client\n    role\n    id\n    email\n  }\n": types.UserFragmentFragmentDoc,
    "\n  query GetUser($userId: String!) {\n    user(id: $userId) {\n      ...UserFragment\n    }\n  }\n": types.GetUserDocument,
    "\n  query GetMe {\n    me {\n      ...UserFragment\n    }\n  }\n": types.GetMeDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveProduct($data: RemoveProductInput!) {\n    removeProduct(data: $data) {\n      ...ProductFragment\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveProduct($data: RemoveProductInput!) {\n    removeProduct(data: $data) {\n      ...ProductFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateProduct($data: CreateProductInput!) {\n    createProduct(data: $data) {\n      ...ProductFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateProduct($data: CreateProductInput!) {\n    createProduct(data: $data) {\n      ...ProductFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProductFragment on Product {\n    id\n    name\n    carrierProductCode\n  }\n"): (typeof documents)["\n  fragment ProductFragment on Product {\n    id\n    name\n    carrierProductCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCarrierProducts {\n    products {\n      id\n      name\n      carrierProductCode\n    }\n  }\n"): (typeof documents)["\n  query GetCarrierProducts {\n    products {\n      id\n      name\n      carrierProductCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateConnection($data: CreateConnectionInput!) {\n    createConnection(data: $data) {\n      connection {\n        id\n        connectionName\n        createdAt\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateConnection($data: CreateConnectionInput!) {\n    createConnection(data: $data) {\n      connection {\n        id\n        connectionName\n        createdAt\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetConnections {\n    connections {\n      id\n      connectionName\n      createdAt\n      status\n      connectionToSelf\n    }\n  }\n"): (typeof documents)["\n  query GetConnections {\n    connections {\n      id\n      connectionName\n      createdAt\n      status\n      connectionToSelf\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateItinerary($data: CreateItineraryInput!) {\n    createItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CreateItinerary($data: CreateItineraryInput!) {\n    createItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AddShipmentsToItinerary($data: ModifyItineraryInput!) {\n    addShipmentsToItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AddShipmentsToItinerary($data: ModifyItineraryInput!) {\n    addShipmentsToItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveShipmentsFromItinerary($data: ModifyItineraryInput!) {\n    removeShipmentsFromItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveShipmentsFromItinerary($data: ModifyItineraryInput!) {\n    removeShipmentsFromItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ReorderItineraryStop($data: ReorderStopInput!) {\n    reorderStop(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation ReorderItineraryStop($data: ReorderStopInput!) {\n    reorderStop(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetItineraryStopOrder($data: SetStopOrderInput!) {\n    setStopOrder(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation SetItineraryStopOrder($data: SetStopOrderInput!) {\n    setStopOrder(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelItinerary($itineraryId: String!) {\n    cancelItinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation CancelItinerary($itineraryId: String!) {\n    cancelItinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AssignItinerary($data: AssignItineraryInput!) {\n    assignItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AssignItinerary($data: AssignItineraryInput!) {\n    assignItinerary(data: $data) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UnassignItinerary($itineraryId: String!) {\n    unAssignItinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UnassignItinerary($itineraryId: String!) {\n    unAssignItinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ItineraryCoreFragment on Itinerary {\n    id\n    name\n    createdAt\n    startDate\n    status\n    shipmentIds\n  }\n"): (typeof documents)["\n  fragment ItineraryCoreFragment on Itinerary {\n    id\n    name\n    createdAt\n    startDate\n    status\n    shipmentIds\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ItineraryStopFragment on ItineraryStop {\n    id\n    addressString\n    city\n    order\n    geoLocation {\n      lat\n      lng\n    }\n  }\n"): (typeof documents)["\n  fragment ItineraryStopFragment on ItineraryStop {\n    id\n    addressString\n    city\n    order\n    geoLocation {\n      lat\n      lng\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ItineraryStopActionFragment on ItineraryStopAction {\n    type\n    status\n    shipment {\n      ...ShipmentFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ItineraryStopActionFragment on ItineraryStopAction {\n    type\n    status\n    shipment {\n      ...ShipmentFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ItineraryFragment on Itinerary {\n    ...ItineraryCoreFragment\n    assignedUser {\n      ...UserFragment\n    }\n    stops {\n      ...ItineraryStopFragment\n      actions {\n        ...ItineraryStopActionFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment ItineraryFragment on Itinerary {\n    ...ItineraryCoreFragment\n    assignedUser {\n      ...UserFragment\n    }\n    stops {\n      ...ItineraryStopFragment\n      actions {\n        ...ItineraryStopActionFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetItinerary($itineraryId: String!) {\n    itinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  query GetItinerary($itineraryId: String!) {\n    itinerary(itineraryId: $itineraryId) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetItineraries($filter: ItineraryFilterInput!) {\n    itineraries(filter: $filter) {\n      ...ItineraryFragment\n    }\n  }\n"): (typeof documents)["\n  query GetItineraries($filter: ItineraryFilterInput!) {\n    itineraries(filter: $filter) {\n      ...ItineraryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InviteUser($data: InviteUserInput!) {\n    inviteUser(data: $data) {\n      ...UserFragment\n    }\n  }\n"): (typeof documents)["\n  mutation InviteUser($data: InviteUserInput!) {\n    inviteUser(data: $data) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveUser($data: RemoveUserInput!) {\n    removeUser(data: $data) {\n      ...UserFragment\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveUser($data: RemoveUserInput!) {\n    removeUser(data: $data) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ConnectOrganizationToIol($data: ConnectOrganizationToIolInput!) {\n    connectOrganizationToIol(data: $data) {\n      connectionUrl\n    }\n  }\n"): (typeof documents)["\n  mutation ConnectOrganizationToIol($data: ConnectOrganizationToIolInput!) {\n    connectOrganizationToIol(data: $data) {\n      connectionUrl\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OrganizationFragment on Organization {\n    id\n    name\n    users {\n      firstName\n      lastName\n      fullName @client\n      role\n      id\n      email\n    }\n  }\n"): (typeof documents)["\n  fragment OrganizationFragment on Organization {\n    id\n    name\n    users {\n      firstName\n      lastName\n      fullName @client\n      role\n      id\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetOrganization($orgId: String) {\n    organization(orgId: $orgId) {\n      ...OrganizationFragment\n    }\n  }\n"): (typeof documents)["\n  query GetOrganization($orgId: String) {\n    organization(orgId: $orgId) {\n      ...OrganizationFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation PickupShipment($data: PickupShipmentInput!) {\n    pickupShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation PickupShipment($data: PickupShipmentInput!) {\n    pickupShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AssignShipment($data: AssignShipmentInput!) {\n    assignShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AssignShipment($data: AssignShipmentInput!) {\n    assignShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UnassignShipment($data: UnassignShipmentInput!) {\n    unassignShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UnassignShipment($data: UnassignShipmentInput!) {\n    unassignShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CancelShipment($data: CancelShipmentInput!) {\n    cancelShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CancelShipment($data: CancelShipmentInput!) {\n    cancelShipment(data: $data) {\n      results {\n        shipmentId\n        shipment {\n          ...ShipmentFragment\n        }\n        errorType\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ShipmentCoreFragment on Shipment {\n    id\n    iolIdentity\n    assignedUserId\n    carrierCode\n    carrierName\n    carrierProductCode\n    consignmentId\n    status\n    shipmentNumber\n    shipmentDate\n    itineraryIds\n  }\n"): (typeof documents)["\n  fragment ShipmentCoreFragment on Shipment {\n    id\n    iolIdentity\n    assignedUserId\n    carrierCode\n    carrierName\n    carrierProductCode\n    consignmentId\n    status\n    shipmentNumber\n    shipmentDate\n    itineraryIds\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ExtraShipmentInformationFragment on Shipment {\n    bookingInstruction\n    productName\n    productCode\n    paymentIndicator\n    freeTextItems\n    deliveryInstruction\n    termsOfDeliveryText\n    termsOfDeliveryLocation\n    termsOfDeliveryCode\n  }\n"): (typeof documents)["\n  fragment ExtraShipmentInformationFragment on Shipment {\n    bookingInstruction\n    productName\n    productCode\n    paymentIndicator\n    freeTextItems\n    deliveryInstruction\n    termsOfDeliveryText\n    termsOfDeliveryLocation\n    termsOfDeliveryCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DangerousGoodsFragment on DangerousGoodsItem {\n    netWeight\n    unNumber\n    typeOfPackageName\n    tunnelRestrictions\n    properties {\n      dataType\n      name\n      value\n    }\n    packingGroup\n    numberOfPackages\n    netVolume\n    marinePollutant\n    identificationNameAlt\n    identificationName\n    id\n    grossWeight\n    flamingPoint\n    classificationCode\n  }\n"): (typeof documents)["\n  fragment DangerousGoodsFragment on DangerousGoodsItem {\n    netWeight\n    unNumber\n    typeOfPackageName\n    tunnelRestrictions\n    properties {\n      dataType\n      name\n      value\n    }\n    packingGroup\n    numberOfPackages\n    netVolume\n    marinePollutant\n    identificationNameAlt\n    identificationName\n    id\n    grossWeight\n    flamingPoint\n    classificationCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ShipmentAddressFragment on ShipmentAddress {\n    id\n    zipCode\n    smsNumber\n    province\n    properties {\n      dataType\n      name\n      value\n    }\n    phoneNumber\n    name\n    iolIdentity\n    identifiers {\n      name\n      value\n    }\n    geoLocation {\n      lat\n      lng\n    }\n    gateCode\n    faxNumber\n    email\n    countryName\n    countryCode\n    contactPerson\n    city\n    addressType\n    address3\n    address2\n    address1\n  }\n"): (typeof documents)["\n  fragment ShipmentAddressFragment on ShipmentAddress {\n    id\n    zipCode\n    smsNumber\n    province\n    properties {\n      dataType\n      name\n      value\n    }\n    phoneNumber\n    name\n    iolIdentity\n    identifiers {\n      name\n      value\n    }\n    geoLocation {\n      lat\n      lng\n    }\n    gateCode\n    faxNumber\n    email\n    countryName\n    countryCode\n    contactPerson\n    city\n    addressType\n    address3\n    address2\n    address1\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PackageItemsFragment on PackageItem {\n    width\n    volume\n    typeOfPackages\n    typeOfPackageCode\n    shippingMark\n    packageNumber\n    loadingmeters\n    length\n    iolIdentity\n    id\n    height\n    grossWeight\n    goodsDescription\n    circumference\n  }\n"): (typeof documents)["\n  fragment PackageItemsFragment on PackageItem {\n    width\n    volume\n    typeOfPackages\n    typeOfPackageCode\n    shippingMark\n    packageNumber\n    loadingmeters\n    length\n    iolIdentity\n    id\n    height\n    grossWeight\n    goodsDescription\n    circumference\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ShipmentEventsFragment on ShipmentEvent {\n    createdAt\n    eventType\n    geoPoint {\n      lat\n      lng\n    }\n    reasonCode\n    reasonText\n    createdBy\n    assignedUserId\n  }\n"): (typeof documents)["\n  fragment ShipmentEventsFragment on ShipmentEvent {\n    createdAt\n    eventType\n    geoPoint {\n      lat\n      lng\n    }\n    reasonCode\n    reasonText\n    createdBy\n    assignedUserId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ShipmentServicesFragment on ShipmentService {\n    serviceName\n    serviceCode\n    properties {\n      dataType\n      name\n      value\n    }\n    description\n    carrierServiceCode\n  }\n"): (typeof documents)["\n  fragment ShipmentServicesFragment on ShipmentService {\n    serviceName\n    serviceCode\n    properties {\n      dataType\n      name\n      value\n    }\n    description\n    carrierServiceCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ShipmentPropertiesFragment on NamedProperty {\n    dataType\n    name\n    value\n  }\n"): (typeof documents)["\n  fragment ShipmentPropertiesFragment on NamedProperty {\n    dataType\n    name\n    value\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ShipmentFragment on Shipment {\n    ...ShipmentCoreFragment\n    ...ExtraShipmentInformationFragment\n    sourceAddress {\n      ...ShipmentAddressFragment\n    }\n    destinationAddress {\n      ...ShipmentAddressFragment\n    }\n    shipmentAddresses {\n      ...ShipmentAddressFragment\n    }\n    shipmentServices {\n      ...ShipmentServicesFragment\n    }\n    dangerousGoodsItems {\n      ...DangerousGoodsFragment\n    }\n    properties {\n      ...ShipmentPropertiesFragment\n    }\n    packageItems {\n      ...PackageItemsFragment\n    }\n    shipmentIdentifiers {\n      value\n      name\n    }\n    events {\n      ...ShipmentEventsFragment\n    }\n  }\n"): (typeof documents)["\n  fragment ShipmentFragment on Shipment {\n    ...ShipmentCoreFragment\n    ...ExtraShipmentInformationFragment\n    sourceAddress {\n      ...ShipmentAddressFragment\n    }\n    destinationAddress {\n      ...ShipmentAddressFragment\n    }\n    shipmentAddresses {\n      ...ShipmentAddressFragment\n    }\n    shipmentServices {\n      ...ShipmentServicesFragment\n    }\n    dangerousGoodsItems {\n      ...DangerousGoodsFragment\n    }\n    properties {\n      ...ShipmentPropertiesFragment\n    }\n    packageItems {\n      ...PackageItemsFragment\n    }\n    shipmentIdentifiers {\n      value\n      name\n    }\n    events {\n      ...ShipmentEventsFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetShipment($shipmentId: String!) {\n    shipment(id: $shipmentId) {\n      ...ShipmentFragment\n    }\n  }\n"): (typeof documents)["\n  query GetShipment($shipmentId: String!) {\n    shipment(id: $shipmentId) {\n      ...ShipmentFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetShipments($filter: ShipmentFilterInput) {\n    shipments(filter: $filter) {\n      ...ShipmentFragment\n    }\n  }\n"): (typeof documents)["\n  query GetShipments($filter: ShipmentFilterInput) {\n    shipments(filter: $filter) {\n      ...ShipmentFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AcceptTransportRequest($data: AcceptShipmentRequestInput!) {\n    acceptShipmentRequest(data: $data) {\n      ...TransportRequestFragment\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptTransportRequest($data: AcceptShipmentRequestInput!) {\n    acceptShipmentRequest(data: $data) {\n      ...TransportRequestFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RejectTransportRequest($data: RejectShipmentRequestInput!) {\n    rejectShipmentRequest(data: $data) {\n      ...TransportRequestFragment\n    }\n  }\n"): (typeof documents)["\n  mutation RejectTransportRequest($data: RejectShipmentRequestInput!) {\n    rejectShipmentRequest(data: $data) {\n      ...TransportRequestFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TransportRequestFragment on ShipmentRequest {\n    id\n    earliestPickupDate\n    latestPickupDate\n    earliestDeliveryDate\n    latestDeliveryDate\n    createdAt\n    status\n    sourceAddress {\n      ...ShipmentAddressFragment\n    }\n    destinationAddress {\n      ...ShipmentAddressFragment\n    }\n    packageItems {\n      ...PackageItemsFragment\n    }\n    maxFreightCharge\n    currencyCode\n  }\n"): (typeof documents)["\n  fragment TransportRequestFragment on ShipmentRequest {\n    id\n    earliestPickupDate\n    latestPickupDate\n    earliestDeliveryDate\n    latestDeliveryDate\n    createdAt\n    status\n    sourceAddress {\n      ...ShipmentAddressFragment\n    }\n    destinationAddress {\n      ...ShipmentAddressFragment\n    }\n    packageItems {\n      ...PackageItemsFragment\n    }\n    maxFreightCharge\n    currencyCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTransportRequests {\n    request {\n      ...TransportRequestFragment\n    }\n  }\n"): (typeof documents)["\n  query GetTransportRequests {\n    request {\n      ...TransportRequestFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserPassword($data: UpdateUserPasswordInput!) {\n    updateUserPassword(data: $data) {\n      ...UserFragment\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserPassword($data: UpdateUserPasswordInput!) {\n    updateUserPassword(data: $data) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserFragment on User {\n    firstName\n    lastName\n    fullName @client\n    role\n    id\n    email\n  }\n"): (typeof documents)["\n  fragment UserFragment on User {\n    firstName\n    lastName\n    fullName @client\n    role\n    id\n    email\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetUser($userId: String!) {\n    user(id: $userId) {\n      ...UserFragment\n    }\n  }\n"): (typeof documents)["\n  query GetUser($userId: String!) {\n    user(id: $userId) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMe {\n    me {\n      ...UserFragment\n    }\n  }\n"): (typeof documents)["\n  query GetMe {\n    me {\n      ...UserFragment\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;