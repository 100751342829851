import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { selectCarrierProductById } from 'store/carrierProducts/selectors'
import { RootState } from 'store'
import CarrierProduct from 'types/CarrierProduct'
import { removeProduct } from 'store/carrierProducts/actions'

const RemoveDialog = ({
  carrierProductId,
  isOpen,
  onClose,
}: {
  carrierProductId: CarrierProduct['id']
  isOpen: boolean
  onClose: () => void
}) => {
  const dispatch = useDispatch()
  const product: CarrierProduct | undefined = useSelector((state: RootState) =>
    selectCarrierProductById(state, carrierProductId)
  )

  const onRemove = () => {
    if (product) {
      dispatch(removeProduct({ productId: product.id }))
    }
    onClose()
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="remove-dialog-title"
      aria-describedby="remove-dialog-description"
    >
      {product && (
        <>
          <DialogTitle id="remove-dialog-title">
            <Translate value="UI.Products.Remove.Title" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="remove-dialog-description">
              <Translate value="UI.Products.Remove.Content" identity={product.name} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onRemove} color="error" autoFocus>
              <Translate value="General.Remove" />
            </Button>
            <Button onClick={onClose} color="primary">
              <Translate value="General.Cancel" />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default RemoveDialog
