import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, DialogActions, DialogContent, DialogTitle, Grid, ListItem } from '@mui/material'
import { Translate } from 'react-redux-i18n'

import { ProductCreateParams } from 'api/types'
import { createProduct } from 'store/carrierProducts/actions'

import LoadingButton from 'components/ui/LoadingButton'
import CreateProductForm from './CreateProductForm'

import { CreateProductDialog, CreateProductList } from './styled'

export type ProductTemplate = {
  name: string
  carrierProductCode: string
}

export type CreateProductModalProps = {
  onClose: () => void
  onInvite: (success: boolean) => void
  isVisible: boolean
}

export const CreateProductModal = ({
  onClose,
  onInvite,
  isVisible: isOpen,
}: CreateProductModalProps) => {
  const dispatch = useDispatch()

  const [product, setProduct] = useState<ProductTemplate>()
  const [inputsValidated, setInputsValidated] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleCreate = () => {
    setLoading(true)
    dispatch(
      createProduct({
        data: product as ProductCreateParams,
        callback: (success: boolean) => {
          setLoading(false)
          onInvite(success)
        },
      })
    )
  }

  const handleProductUpdate = useCallback((p: ProductTemplate) => {
    setProduct(p)
  }, [])

  const handleValidationUpdate = (valid: boolean) => {
    setInputsValidated(valid)
  }

  return (
    <CreateProductDialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="create-product-dialog-title"
    >
      <DialogTitle id="create-product-dialog-title">
        <Translate value="UI.Products.Add.AddProduct" />
      </DialogTitle>
      <DialogContent>
        <CreateProductList>
          <ListItem dense disableGutters>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12}>
                <CreateProductForm
                  onChange={handleProductUpdate}
                  onValidation={handleValidationUpdate}
                />
              </Grid>
            </Grid>
          </ListItem>
        </CreateProductList>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleCreate}
          color="primary"
          variant="outlined"
          disabled={!inputsValidated}
          label={<Translate value="UI.Products.Add.AddProduct" />}
          isLoading={isLoading}
        />
        <Button onClick={onClose} color="error">
          <Translate value="General.Cancel" />
        </Button>
      </DialogActions>
    </CreateProductDialog>
  )
}
export default CreateProductModal
