import { graphql } from 'gql/gql'

export const RemoveProductMutation = graphql(`
  mutation RemoveProduct($data: RemoveProductInput!) {
    removeProduct(data: $data) {
      ...ProductFragment
    }
  }
`)

export const CreateProductMutation = graphql(`
  mutation CreateProduct($data: CreateProductInput!) {
    createProduct(data: $data) {
      ...ProductFragment
    }
  }
`)
