import { nanoid } from '@reduxjs/toolkit'
import api from 'api'
import { I18n } from 'react-redux-i18n'
import { newRequest } from 'store/snackbars/actions'
import { call, put, takeLatest } from 'redux-saga/effects'
import { CarrierProduct } from 'types'
import { getCarrierProducts, createProduct, removeProduct } from './actions'

function* getHandler({ payload = {} }: ReturnType<typeof getCarrierProducts>) {
  try {
    if (!payload.silent) yield put(getCarrierProducts.pending())

    const data: CarrierProduct[] = yield call(api.CarrierProduct.getAll)
    yield put(getCarrierProducts.success(data))
  } catch (error: any) {
    if (!payload.silent) yield put(getCarrierProducts.failure(error.message))
  }
}

function* removeHandler({ payload }: ReturnType<typeof removeProduct>) {
  const snackId = nanoid()
  const messages = {
    pending: I18n.t('UI.Products.StatusMessages.Remove.Loading'),
    success: I18n.t('UI.Products.StatusMessages.Remove.Success', {
      product: payload.productId,
    }),
    failure: I18n.t('UI.Users.StatusMessages.Remove.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    const result: { removeProduct: any } | null = yield call(api.CarrierProduct.remove, payload)
    if (result !== null) {
      yield put(newRequest.success({ id: snackId, message: messages.success }))
    } else {
      yield put(newRequest.failure({ id: snackId, message: messages.failure }))
    }
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
  }
}

function* createHandler({ payload }: ReturnType<typeof createProduct>) {
  const snackId = nanoid()

  const messages = {
    pending: I18n.t('UI.Products.StatusMessages.Create.Loading'),
    success: I18n.t('UI.Products.StatusMessages.Create.Success'),
    failure: I18n.t('UI.Products.StatusMessages.Create.Failed'),
  }

  try {
    yield put(newRequest.pending({ id: snackId, message: messages.pending }))
    yield call(api.CarrierProduct.createProduct, payload.data)
    yield put(newRequest.success({ id: snackId, message: messages.success }))
    //Refresh the table so it shows after creation
    yield put(getCarrierProducts())
    payload.callback?.(true)
  } catch (error: any) {
    yield put(
      newRequest.failure({
        id: snackId,
        message: error?.message || messages.failure,
      })
    )
    payload.callback?.(false)
  }
}

export default [
  takeLatest(getCarrierProducts.type, getHandler),
  takeLatest(createProduct.type, createHandler),
  takeLatest(removeProduct.type, removeHandler),
]
