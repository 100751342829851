import React, { ReactNode } from 'react'
import { MUIDataTableColumn, MUIDataTableMeta } from 'mui-datatables'
import { I18n, Translate } from 'react-redux-i18n'

import { CustomColumnOptions } from 'helpers/types'
import { SORT_ORDER } from 'components/features/data/DataGrid'
import columnConstructors from 'components/features/data/columnConstructors'
import { dateAndTime, dayMonth } from 'helpers/formatDate'
import TransportRequestStatusChip from '../RequestStatusChip'
import { TransportRequest, TransportRequestStatus } from 'types'
import mergeColumnOptions from 'helpers/mergeColumnOptions'
import shortenUUID from 'helpers/shortenUUID'
import { compareStrings } from 'helpers/compare'

export enum TransportRequestColumns {
  ShipmentId = 'id',
  CreatedAt = 'createdAt',
  PickupAddress = 'sourceAddress.address1',
  DeliveryAddress = 'destinationAddress.address1',
  PickupZipCode = 'sourceAddress.zipCode',
  DeliveryZipCode = 'destinationAddress.zipCode',
  PickupCity = 'sourceAddress.city',
  DeliveryCity = 'destinationAddress.city',
  Weight = 'packageItems',
  Packages = 'packageItems',
  EarliestPickup = 'earliestPickupDate',
  LatestDelivery = 'latestDeliveryDate',
  MaxCharge = 'maxFreightCharge',
  Actions = 'actions',
  Status = 'status',
}

const getTransportRequestColumns: (
  data: TransportRequest[],
  actions: (tableMeta: MUIDataTableMeta) => ReactNode,
  columnOptions?: CustomColumnOptions
) => MUIDataTableColumn[] = (data, actions, columnOptions) => {
  const columns: MUIDataTableColumn[] = [
    {
      label: I18n.t('UI.Requests.Table.ShipmentId'),
      name: TransportRequestColumns.ShipmentId,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Requests.Table.ShipmentId" />,
        customBodyRender: (value) => shortenUUID(value),
        sortCompare:
          (order) =>
          ({ data: a }, { data: b }) =>
            compareStrings(order === SORT_ORDER.ASCENDING ? 1 : -1, a, b),
        filter: false,
      },
    },
    {
      label: I18n.t('UI.Itineraries.Table.Columns.CreatedAt'),
      name: TransportRequestColumns.CreatedAt,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Itineraries.Table.Columns.CreatedAt" />,
        customBodyRender: (value?: string | null) =>
          value ? dayMonth(value) : I18n.t('UI.Requests.Table.UnknownDate'),
      },
    },
    {
      label: I18n.t('UI.Requests.Table.Status'),
      name: TransportRequestColumns.Status,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Requests.Table.Status" />,
        customBodyRenderLite: (dataIndex) => {
          const { status } = data[dataIndex]
          return <TransportRequestStatusChip status={status} />
        },
        customFilterListOptions: {
          render: (status) => <Translate value={`Entities.TransportRequest.Status.${status}`} />,
        },
        filterOptions: {
          renderValue: (status) => I18n.t(`Entities.Shipment.Status.${status}`),
          names: Object.values(TransportRequestStatus),
        },
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.PickupAddress'),
      name: TransportRequestColumns.PickupAddress,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.PickupAddress" />,
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.PickupCity'),
      name: TransportRequestColumns.PickupCity,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.PickupCity" />,
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.PickupZipCode'),
      name: TransportRequestColumns.PickupZipCode,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.PickupZipCode" />,
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.DeliveryAddress'),
      name: TransportRequestColumns.DeliveryAddress,
      options: {
        customHeadLabelRender: () => (
          <Translate value="UI.Shipments.Table.Columns.DeliveryAddress" />
        ),
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.DeliveryCity'),
      name: TransportRequestColumns.DeliveryCity,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Shipments.Table.Columns.DeliveryCity" />,
      },
    },
    {
      label: I18n.t('UI.Shipments.Table.Columns.DeliveryZipCode'),
      name: TransportRequestColumns.DeliveryZipCode,
      options: {
        customHeadLabelRender: () => (
          <Translate value="UI.Shipments.Table.Columns.DeliveryZipCode" />
        ),
      },
    },
    {
      label: I18n.t('UI.Requests.Table.EearliestPickup'),
      name: TransportRequestColumns.EarliestPickup,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Requests.Table.EearliestPickup" />,
        customBodyRender: (value: string | null) =>
          value ? dateAndTime(value) : I18n.t('UI.Requests.Table.UnknownDate'),
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Requests.Table.LatestDelivery'),
      name: TransportRequestColumns.LatestDelivery,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Requests.Table.LatestDelivery" />,
        customBodyRender: (value?: string | null) =>
          value ? dateAndTime(value) : I18n.t('UI.Requests.Table.UnknownDate'),
        filterType: 'multiselect',
      },
    },
    {
      label: I18n.t('UI.Requests.Table.MaxCharge'),
      name: TransportRequestColumns.MaxCharge,
      options: {
        customHeadLabelRender: () => <Translate value="UI.Requests.Table.MaxCharge" />,
        customBodyRenderLite: (dataIndex) => {
          const { currencyCode, maxFreightCharge } = data[dataIndex]
          return maxFreightCharge !== null && maxFreightCharge !== undefined && currencyCode
            ? `${maxFreightCharge} ${currencyCode}`
            : I18n.t('UI.Requests.Table.UnknownDate')
        },
      },
    },
    columnConstructors.actions(actions, TransportRequestColumns.Actions),
  ]

  mergeColumnOptions(columnOptions, columns)

  return columns
}

export default getTransportRequestColumns
